<template>
  <footer class="bg-black text-gold text-center py-4">
    <div class="container">
      <p class="mb-0 text-gold">&copy; 2024 Portfolio. Master Supakon Karanyawad</p>
      <p class="mb-0">This portfolio uses <span class="text-gold">Framework </span><span class="text-red"> Vue.js <span class="text-gold">&amp;</span> Sanity</span><span class="text-gold"> Headless CMS</span></p>
    </div>
  </footer>
</template>

<script>
export default {
    name: 'FooterComponent'
}
</script>

<style scoped>
</style>
